.hero {
  background: url("../../img/bgREAL.jpg");
  height: 100vh;
  background-position: 40% 70%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  filter: grayscale(60%);
  flex-direction: column;
  justify-content: flex-end;
}

.logo {
  transition: all 0.3s ease;
  width: 250px;
  position: absolute;
  left: 50vw;
  transform: translateX(-50%);
  top: 30vh;
  z-index: 1000;
}

.logoSmall {
  width: 80px;
  left: var(--space-m);
  transform: translateX(0);
}

@keyframes logoGone {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.logoGone {
  animation: logoGone 0s forwards;
}
