.AppBar {
  transition: background-color 0.3s;
  background-color: var(--gray-transparent);
  box-shadow: none;
  display: flex;
  align-items: space-between;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  width: 100%;
  height: 55px;
  padding: 0 var(--space-m);
  color: var(--white);
}

.AppBarRoot {
  max-width: 1600px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .AppBar {
    height: 65px;
  }
}
.AppBar.Ready {
  background-color: var(--gray-700);
  box-shadow: none;
  display: flex;
  align-items: space-between;
}
.AppBarLogo {
  position: relative;
  object-fit: contain;
  width: 80px;
  opacity: 0;
}

.AppBarLogo.Ready {
  opacity: 1;
}
