body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --space-m: 16px;
  --white: #fff;
  --black: #000;
  --gray-50: #f9f9f9;
  --gray-100: #ececec;
  --gray-200: #cdcdcd;
  --gray-300: #b4b4b4;
  --gray-400: #9b9b9b;
  --gray-500: #676767;
  --gray-600: #424242;
  --gray-700: #2f2f2f;
  --gray-800: #212121;
  --gray-900: #171717;
  --gray-950: #0d0d0d;
  --gray-transparent: rgba(36, 36, 36, 0.662);
  --brand-red: rgb(255, 110, 110);
  --brand-red-transparent: rgb(255, 110, 110, 0.2);
}

@media (min-width: 600px) {
  :root {
    --space-m: 24px;
  }
}
